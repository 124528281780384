var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "正在產生病歷格式" }
    },
    [
      _c(
        "el-button",
        {
          staticStyle: { margin: "3px 0" },
          attrs: {
            icon: "el-icon-download",
            type: "primary",
            id: "download_health_btn"
          },
          on: { click: _vm.fetchData }
        },
        [_vm._v("匯出病歷")]
      ),
      _vm._l(_vm.person_List, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "single_column_print",
            staticStyle: { margin: "30px 0" }
          },
          [
            _vm._m(0, true),
            _c("div", { attrs: { id: "first_origin_basic_data" } }, [
              _c("table", { staticStyle: { margin: "0px 10px" } }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "1", width: "100" } }, [
                      _vm._v(" 姓名 ")
                    ]),
                    _c("td", { attrs: { colspan: "1", width: "150" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_member_username) + " "
                      )
                    ]),
                    _c("td", { attrs: { colspan: "1", width: "50" } }, [
                      _vm._v(" 性別 ")
                    ]),
                    _c("td", { attrs: { colspan: "1", width: "50" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("reserve_system_member_sex")(
                              item.reserve_system_member_sex
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { colspan: "2", width: "150" } }, [
                      _vm._v(" 出生年月日 ")
                    ]),
                    _c("td", { attrs: { colspan: "2", width: "200" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_member_birthday) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "1", width: "100" } }, [
                      _vm._v(" 病歷號 ")
                    ]),
                    _c("td", { attrs: { colspan: "3", width: "150" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_health_record_id) + " "
                      )
                    ]),
                    _c("td", { attrs: { colspan: "2", width: "150" } }, [
                      _vm._v(" 身分證字號 ")
                    ]),
                    _c("td", { attrs: { colspan: "2", width: "300" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_member_identity) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "1", width: "100" } }, [
                      _vm._v(" 職業 ")
                    ]),
                    _c("td", { attrs: { colspan: "3", width: "150" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_member_career_other) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { colspan: "2", width: "150" } }, [
                      _vm._v(" 電話 ")
                    ]),
                    _c("td", { attrs: { colspan: "2", width: "300" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_member_telephone) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "1", width: "100" } }, [
                      _vm._v(" 地址 ")
                    ]),
                    _c("td", { attrs: { colspan: "7", width: "600" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_member_address) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "1", width: "100" } }, [
                      _vm._v(" 注意事項 ")
                    ]),
                    _c("td", { attrs: { colspan: "7", width: "600" } }, [
                      _vm._v(
                        " " + _vm._s(item.reserve_system_member_note) + " "
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticStyle: { margin: "10px" },
                attrs: { id: "second_three_origin_basic_data" }
              },
              [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "1", width: "300" } }, [
                        _vm._v(
                          " 2、初診/複診：" +
                            _vm._s(
                              _vm._f("reserve_system_health_first_record")(
                                item.reserve_system_health_first_record
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("td", { attrs: { colspan: "1", width: "500" } }, [
                        _vm._v(
                          " 3、治療日期： " +
                            _vm._s(
                              item.reserve_system_shop_basic_schedule_start_date
                            ) +
                            " "
                        )
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { margin: "10px" },
                attrs: { id: "four_origin_basic_data" }
              },
              [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "1", width: "800" } }, [
                        _vm._v(
                          " 4、治療部位：" +
                            _vm._s(
                              item.reserve_system_health_person_treat_site
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              item.reserve_system_health_person_treat_other_site !=
                              "",
                            expression:
                              "item.reserve_system_health_person_treat_other_site!=''"
                          }
                        ],
                        staticStyle: { margin: "15px 0" }
                      },
                      [
                        _c("td", { attrs: { colspan: "1", width: "800" } }, [
                          _vm._v(
                            " 其他：" +
                              _vm._s(
                                item.reserve_system_health_person_treat_other_site
                              ) +
                              " "
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { margin: "10px" },
                attrs: { id: "five_origin_basic_data" }
              },
              [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "1", width: "800" } }, [
                        _vm._v(" 5、治療項目： "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.reserve_system_health_person_treat_health_item !=
                                  "",
                                expression:
                                  "item.reserve_system_health_person_treat_health_item!=''"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.reserve_system_health_person_treat_health_item
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { margin: "10px" },
                attrs: { id: "six_origin_basic_data" }
              },
              [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "1", width: "800" } }, [
                        _vm._v(" 6、治療紀錄： "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.reserve_system_health_person_record !=
                                  "",
                                expression:
                                  "item.reserve_system_health_person_record!=''"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.reserve_system_health_person_record
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._m(1, true),
            _c(
              "div",
              {
                staticClass: "noBreak",
                staticStyle: { margin: "10px" },
                attrs: { id: "seven_origin_basic_data" }
              },
              [
                item.reserve_system_health_record_photo.length >= 1
                  ? _c("table", [
                      _c("tbody", [
                        _c("tr", [
                          item.reserve_system_health_record_photo.length >= 1
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[0][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.reserve_system_health_record_photo.length >= 2
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[1][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("tr", [
                          item.reserve_system_health_record_photo.length >= 3
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[2][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.reserve_system_health_record_photo.length >= 4
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[3][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("tr", [
                          item.reserve_system_health_record_photo.length >= 5
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[4][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.reserve_system_health_record_photo.length >= 6
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[5][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("tr", [
                          item.reserve_system_health_record_photo.length >= 7
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[6][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.reserve_system_health_record_photo.length >= 8
                            ? _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: "1",
                                    width: "400",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        src:
                                          _vm.upload_path +
                                          item
                                            .reserve_system_health_record_photo[7][
                                            "reserve_system_health_record_photo"
                                          ]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                item.reserve_system_health_record_photo.length == 0
                  ? _c("p", [_vm._v("無治療照片")])
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "article__heading" }, [
              _c(
                "div",
                {
                  staticClass: "article__heading__title",
                  staticStyle: {
                    margin: "3px 0",
                    "font-size": "15px",
                    "text-align": "right"
                  }
                },
                [
                  _vm._v(
                    " 治療師:_____________" +
                      _vm._s(item.reserve_system_shop_basic_schedule_teacher) +
                      "_____________ 日期:_____________" +
                      _vm._s(
                        item.edit_reserve_system_health_datetime == ""
                          ? item.reserve_system_health_datetime
                          : item.edit_reserve_system_health_datetime
                      ) +
                      "______________ "
                  )
                ]
              )
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article__heading" }, [
      _c(
        "div",
        {
          staticClass: "article__heading__title",
          staticStyle: {
            margin: "3px 0",
            "font-size": "15px",
            "text-align": "center",
            "font-weight": "700"
          }
        },
        [_vm._v(" 物理治療評估紀錄表 ")]
      ),
      _c(
        "div",
        {
          staticClass: "article__heading__title",
          staticStyle: {
            margin: "3px 0",
            "font-size": "15px",
            "text-align": "center",
            "font-weight": "700"
          }
        },
        [_vm._v(" Physical Therapy Evaluation Note ")]
      ),
      _c(
        "div",
        {
          staticClass: "article__heading__title",
          staticStyle: {
            "margin-top": "3px",
            "margin-bottom": "3px",
            "margin-left": "10px",
            "font-size": "15px",
            "text-align": "left"
          }
        },
        [_vm._v(" 1、基本資料 ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article__heading" }, [
      _c(
        "div",
        {
          staticClass: "article__heading__title",
          staticStyle: {
            "margin-top": "3px",
            "margin-bottom": "3px",
            "margin-left": "10px",
            "font-size": "15px",
            "text-align": "left"
          }
        },
        [_vm._v(" 7、治療照片： ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }